import React from 'react';
import LandingPageTemplate from '../components/LandingPageTemplate';
import mainImage from '../images/automatiktuer_wartung_1600.webp';
import MaintenanceForm from "../components/MaintenanceForm";


const context = {
  title: 'Wartungsangebot für automatische Türanlagen', // Große Haupt-Überschrift
  subTitle: 'Automatiktür24 - Angebot für Wartung', // Kleine Seiten-Überschrift über der Haupt-Überschrift
  description: 'Vorteile einer Wartung durch Automatiktür24',
  image: mainImage, //Haupt-Bild der Landingpage
  imageAlt: "Wartung einer automatischen Schiebetür durch Automatiktür24 Techniker", //Alternativtext / Beschreibung zum Haupt-Bild
  contactButtonText: "Kontaktformular",
  suggestedProductsTitle: "",
  suggestedProducts: [],
  text:
    <div>
      <p className="pb-5">Wir von Automatiktür24 möchten uns Ihnen gerne als zuverlässigen Servicepartner für Ihre
        automatischen Türanlagen vorstellen. Wir sind ein junges, modernes Unternehmen und bieten turnusmäßige
        Wartungen Ihrer automatischen Schiebetüren, Drehtüren und Feststellanlagen an Brandund Rauchschutztüren an.</p>

      <p className="pb-5">Moderne Servicefahrzeuge und Equipment sowie die Verwendung digitaler Serviceberichte sind
        für uns selbstverständlich. Eine nachhaltige Wartung und eine dadurch möglichst lange und reibungslose
        Betriebsdauer Ihrer Türanlage steht bei uns im Mittelpunkt. Dabei ist uns besonders der partnerschaftliche
        Austausch mit unseren Kunden wichtig.</p>

      <p className="pb-5">Jede automatische Türanlage sollte je nach Bauart jährlich oder halbjährlich gewartet werden.
        Eine regelmäßige Überprüfung Ihrer Türanlagen bietet folgende Vorteile:</p>

      <div className="mx-1 sm:mx-10 bg-green-600 rounded-lg px-6 pt-3 pb-4">
        <ul className="text-white benefitsList">
          <li>Verlängerung der Lebensdauer Ihrer Türen</li>
          <li>Einhaltung geltender Vorschriften und Normen</li>
          <li>Gewährleistung der Sicherheit für Ihre Kunden und Mitarbeiter</li>
          <li>Vermeidung unnötiger Reparaturkosten durch rechtzeitigen Ersatz von Verschleißteilen</li>
          <li>Verbesserung der Funktionsfähigkeit und Zuverlässigkeit Ihrer Türen</li>
          <li>Schnellere Behebung im Störungsfall als Wartungskunde</li>
        </ul>
      </div>

      <h2 className="mt-16 mb-3 lg:text-xl font-semibold">Angebot anfragen</h2>
      <p className="mb-6">Teilen Sie uns die Eckdaten Ihrer automatischen Tür(en) mit.
        Wir erstellen Ihnen umgehen ein Angebot für die Wartung Ihrer Automatiktüren.</p>

      <div className="xl:flex">
        <MaintenanceForm />
      </div>


      <h2 className="mt-16 mb-3 lg:text-xl font-semibold">Persönliche Beratung</h2>
      <p className="mb-6">Sie haben weitere Fragen zur Wartung ihrer automatischen Schiebetür?
        Nutzen Sie das persönliche Gespräch mit einem unserer Tür-Experten.
      </p>
      <p className="hidden md:block">
        Tel. <a href="tel:+4975245092939">07524 / 509 29 39</a>
      </p>

    </div>
}

const Page = () => {

  return(
    <LandingPageTemplate pageContext={context} />
  )
}

export default Page;
