import React, {useState} from 'react';
import {trackScreenView} from '../functions/analytics';
import {FormField} from './FormField';
import FormCheckbox from './FormCheckbox';
import {UploadField} from './UploadField';
import deleteIcon from '../svg/delete.svg';
import axios from '../functions/axios.config';
import LoadingSpinner from '../svg/LoadingSpinner';
import Check from '../svg/Check';
import {FormTextarea} from "./FormTextarea";
import photoHelp from "../images/wartung_angebot_foto_hilfe.webp";
import helpIcon from "../svg/help.svg";
import Tippy from "@tippyjs/react";


const MaintenanceForm = (props) => {

  const inputDefaults = {
    uploadFiles: [],
    firstName: '',
    lastName: '',
    company: '',
    phone: '',
    email: '',
    message: 'Bitte erstellen Sie ein Wartungsangebot für folgende Tür(en):\n' +
      '\n' +
      '1. Tür:\n' +
      '  Standort:\n' +
      '  Hersteller:\n' +
      '  Art der Tür:\n\n' +
      '2. Tür:\n' +
      '  Standort:\n' +
      '  Hersteller:\n' +
      '  Art der Tür:\n\n usw.',
    privacy: false,
  }

  const [input, setInput] = useState({...inputDefaults})
  const [errors, setErrors] = useState(new Set())
  const [forceUpdate, setForceUpdate] = useState(1)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const inputChangeHandler = (event) => {
    const key = event.target.name
    const value = event.target.value
    setInput({
      ...input,
      [key]: value,
    })
    // if error exits, we want a direct re-evaluation. otherwise wait until blur
    errors.has(key) ? validateInput(event.target) : null
  }

  const checkboxChangeHandler = (event) => {
    const key = event.target.name
    const value = event.target.checked
    setInput({
      ...input,
      [key]: value,
    })
    // validate on every change
    validateInput({name:key, value:value})
  }

  const inputBlurHandler = (fieldId) => {
    const target = {name: fieldId, value: input[fieldId]}
    validateInput(target)
    setForceUpdate(forceUpdate+1)
  }

  const handlers = {
    inputChangeHandler: inputChangeHandler,
    inputBlurHandler: inputBlurHandler,
    checkboxChangeHandler: checkboxChangeHandler,
    input: input,
    errors: errors,
  }

  const validateForm = () => {
    for(const field in inputDefaults) {
      validateInput({name:field, value:input[field]})
    }
    setForceUpdate(forceUpdate+1) //hack to force rerender to show errors
  }

  const validateInput = (target) => {
    if(validationRuleContainsError(target.name, target.value)) {
      setErrors(errors => errors.add(target.name))
    } else {
      const newErrors = errors
      newErrors.delete(target.name)
      setErrors(newErrors)
    }
  }

  const validationRuleContainsError = (name, value) => {
    let error;
    switch (name) {
      case 'firstName':
        if (typeof(value) === 'undefined' || value === null || value === '' || value.length < 2) error = true; break;
      case 'lastName':
        if (typeof(value) === 'undefined' || value === null || value === '' || value.length < 2) error = true; break;
      case 'message':
        if (typeof(value) === 'undefined' || value === null || value === '' || value.length < 2) error = true; break;
      case 'phone':
        if (typeof(value) === 'undefined' || value === null || !RegExp('^[0-9\\-\(\)\+\/ ]{7,25}$').test(value)) error = true; break;
      case 'email':
        if (typeof(value) === 'undefined' || value === null || !RegExp("^.+@.+(\\.).+$").test(value)) error = true; break;
      case 'privacy':
        if (typeof(value) === 'undefined' || value === null || value === false) error = true; break;
      default:
        error = false;
    }
    return error;
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    await validateForm()
    if(errors.size > 0) {
      trackScreenView('Risikoanalyse Validierungsfehler')
    } else {
      postMessage(input)
      trackScreenView('Risikoanalyse gesendet')
    }
  }

  const postMessage = (payload) => {
    setLoading(true)
    axios.post('/maintenanceForm',payload)
      .then ( response => {
        console.log('message sent')
        setSuccess(true)
        setLoading(false)
        return true
      })
      .catch(error => {
        console.log('API error')
        setSuccess(false)
        setLoading(false)
        return false
      })
  }

  const uploadSuccessHandler = (name, uuid) => {
    setInput({
      ...input,
      uploadFiles: [...input.uploadFiles, {name: name, uuid: uuid}],
    })
  }

  const removeUploadHandler = (uuid) => {
    const newArray = input.uploadFiles.filter(i => i.uuid !== uuid)
    setInput({
      ...input,
      uploadFiles: newArray,
    })
  }

  const LoadingButton = () => {
    return(
      <button
        className="bg-blue-500 text-white font-bold py-2 px-8 rounded focus:outline-none focus:shadow-md ml-3 cursor-wait"
        type="button"
      >
        Wartungsangebot anfordern<br/>
        <span className="font-normal text-xs">
          <div className="flex items-center justify-center text-center pt-1"><LoadingSpinner /></div>
        </span>
      </button>
    )
  }

  const SendButton = () => {
    return(
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-8 rounded focus:outline-none focus:shadow-md ml-3"
        type="submit"
      >
        Wartungsangebot anfordern<br/>
        <span className="font-normal text-xs">kostenlos & unverbindlich</span>
      </button>
    )
  }

  const ReadyButton = () => {
    return(
      <button
        className="bg-blue-300 text-white font-bold py-2 px-8 rounded focus:outline-none focus:shadow-md ml-3 inline-flex cursor-default"
        type="button"
      >
        <Check /> Vielen Dank für Ihre Anfrage
      </button>
    )
  }

  let formButton = <SendButton />
  if(loading) formButton = <LoadingButton />
  if(success) formButton = <ReadyButton />

  return(
    <div className="w-full">
      <form id="maintenanceContactForm" onSubmit={handleSubmit} autoComplete="on" className="bg-gray-100 shadow-md rounded px-2 md:px-8 pt-6 pb-8 mb-4 text-left">

        <div className="flex justify-end text-sm text-gray-500 mb-4">*Pflichtfeld</div>

        {FormField({
          fieldId: 'firstName',
          fieldName:'Vorname',
          required: true,
          handlers: handlers,
          errorText: 'Bitte geben Sie Ihren Vornamen an.'
        })}
        {FormField({
          fieldId: 'lastName',
          fieldName:'Nachname',
          required: true,
          handlers: handlers,
          errorText: 'Bitte geben Sie Ihren Nachnamen an.'
        })}
        {FormField({
          fieldId: 'company',
          fieldName:'Firma',
          required: false,
          handlers: handlers,
        })}
        {FormField({
          fieldId: 'phone',
          fieldName:'Telefon',
          required: true,
          handlers: handlers,
          errorText: 'Bitte geben Sie eine gültige Telefonnummer an.'
        })}
        {FormField({
          fieldId: 'email',
          fieldName:'E-Mail',
          required: true,
          handlers: handlers,
          errorText: 'Bitte geben Sie eine gültige E-Mail-Adresse an.'
        })}
        {FormTextarea({
          fieldId: 'message',
          fieldName:'Anfrage',
          required: false,
          handlers: handlers,
          rows: 14,
          errorText: 'Bitte tragen Sie Ihre Anfrage ein.'
        })}

        <div className="flex flex-row items-start items-center">
          <label className="text-gray-700 text-sm font-bold ml-2 w-20 hidden md:block">Fotos</label>
          <div className="w-full md:w-2/3 -ml-2">
            <UploadField
              uploadFieldId={'wartungsanfrage'}
              uploadSuccessHandler={uploadSuccessHandler}
              apiUrl={'/api/upload/'}
              mimeType="image/*, application/pdf"
              text1="Foto / PDF hochladen"
              text2="Bestehender Wartungsvertrag oder Bilder der Tür"
            />

            <div id="photoList" className="mb-4 ml-3">
              <ul className="list-disc text-sm">
                { input.uploadFiles.map(fileEntry => (
                  <li className="flex" key={fileEntry.uuid}>
                    <div className="rounded-md bg-indigo-100 flex p-2 m-1">
                      {fileEntry.name}
                      <img src={deleteIcon} className="w-5 ml-2 cursor-pointer hover:bg-white" alt="Datei löschen" onClick={() => removeUploadHandler(fileEntry.uuid)}/>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <Tippy theme="light" content={
            <div>
              <img src={photoHelp} style={{width:330, marginBottom:25}} alt="Automatiktür Durchgangsbreite Skizze" />

              <p className="font-bold">Was soll ich fotografieren?</p>
              <p className="ml-2 mb-1">Bitte fotografiere Sie entweder ihren bestehenden Wartungsvertrag oder die zu wartende Tür(en) mit Typenschild.</p>

              <p className="font-bold">Warum?</p>
              <p className="ml-2 mb-1">Mit diesen Fotos können wir Ihnen schneller und passgenauer ein Wartungs-Angebot für Ihre Tür anfertigen.</p>

              <p className="font-bold">Muss ich Fotos mitschicken?</p>
              <p className="ml-2 mb-1">Nein. Gerne können Sie auch nur die notwendigen Daten im Textfeld eintragen.</p>
            </div>
          }>
            <img src={helpIcon} className="ml-1 mr-5 w-5 text-blue-500" alt="Hilfe" />
          </Tippy>
        </div>

        <div className="mt-3 lg:ml-24">
          {FormCheckbox({
            fieldId: 'privacy',
            fieldName: <div>Ja, ich stimme der <a href="/datenschutz/" target="_blank">Datenschutzerklärung</a> zu.
              <br/>Ein Widerruf ist jederzeit möglich.</div>,
            handlers: handlers,
            errorText: 'Bitte bestätigen Sie die Datenschutzerklärung'
          })}
        </div>


        <div className="flex items-center mt-8 lg:ml-24">
          {formButton}
        </div>

      </form>
    </div>
  )

}

export default MaintenanceForm
